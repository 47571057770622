import { brandColors, breakpointRules, grid, Typography } from '@aceandtate/ds';
import styled from 'styled-components';
import Breadcrumb from '../Breadcrumb';

export const SidebarContainer = styled.div`
  background: ${brandColors.sand};
  flex-basis: 50%;
  padding: ${grid[24]} ${grid[24]};
  @media ${breakpointRules.laptop} {
    padding: ${grid[24]} ${grid.padding};
  }
`;

export const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  @media ${breakpointRules.tablet} {
    flex-direction: row;
    min-height: 100vh;
  }
`;

export const StyledShowOnTablet = styled.div`
  display: none;
  @media ${breakpointRules.tablet} {
    padding: ${grid[60]} 0px;
    display: block;
  }
`;

export const CardBackground = styled.div`
  background: #fff;
  display: flex;
  border-radius: 6px;
  flex-flow: column-reverse;
  overflow: hidden;
  > * {
    flex-basis: 50%;
  }
  max-width: 504px;
`;

export const CardText = styled(Typography)`
  padding: ${grid[12]} ${grid[12]};
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

export const SpacedBreadcrumb = styled(Breadcrumb)`
  margin-bottom: ${grid[24]};
  @media ${breakpointRules.laptop} {
    margin-bottom: ${grid[60]};
  }
`;

export const SidebarTitle = styled(Typography).attrs({ variant: 'h1' })`
  @media ${breakpointRules.laptop} {
    line-height: 64px;
  }
  @media ${breakpointRules.desktop} {
    line-height: 72px;
  }
`;
